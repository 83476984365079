
import http from './http';
class ReqData{
    constructor(model, method, data) {
        this.model = model;
        this.method = method;
        this.params = { ...data }
    }
}
export default {
    uniModel: modelName => {
        return {
            findAll: () => {
                return http.apiCloud(new ReqData(modelName, 'FIND_ALL'));
            },
            findAllBy: (where = {}, opt) => {
                return http.apiCloud(new ReqData(modelName, 'FIND_ALL_BY', { where, opt: { ...opt } }));
            },
            findBySkuIds: skuIds => {
                return http.apiCloud(new ReqData(modelName, 'FIND_BY_SKUIDS', { skuIds }));
            },
            findByIds: (ids = []) => {
                return http.apiCloud(new ReqData(modelName, 'FIND_BY_IDS', { ids }));
            },
            findOneBy: opt => {
                return http.apiCloud(new ReqData(modelName, 'FIND_ONE_BY', { ...opt }));
            },
            findOneById: id => {
                return http.apiCloud(new ReqData(modelName, 'FIND_ONE_BY_ID', { id }));
            },
            findByPage: (page, where = {}) => {
                return http.apiCloud(new ReqData(modelName, 'FIND_BY_PAGE', { page, where }));
            },
            add: (opt) => {
                return http.apiCloud(new ReqData(modelName, 'ADD', { data: {...opt} }))
            },
            get: opt => {
                return http.apiCloud(new ReqData(modelName, 'GET', { ...opt }));
            },
            remove: id => {
                return http.apiCloud(new ReqData(modelName, 'DELETE', { id }));
            },
            update: (id, updateData) => {
                return http.apiCloud(new ReqData(modelName, 'UPDATE', { id, updateData }));
            },
            updateByOpenid: (openid, updateData) => {
                return http.apiCloud(new ReqData(modelName, 'UPDATE_BY_OPENID', { openid, updateData }));
            },
            req: (method, params = {}) => {
                return http.apiCloud(new ReqData(modelName, method, params));
            },
            count: where => {
                return http.apiCloud(new ReqData(modelName, 'COUNT', where));
            }
        }
    },
    fetchListenData: quesId => {
        return http.apiCloud({
            model: 'ques',
            method: 'FIND_ONE_BY_ID',
            params: { id: quesId }
        })
    },
    getOriginByQuesId: (quesId) => {
        return http.apiCloud({
            model: 'originText',
            method: 'FIND_ONE_BY',
            params: {
                quesId
            }
        })
    }
}


