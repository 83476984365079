<template>
    <router-view />
</template>

<script>

import { provide } from 'vue';
import useUser from './store/useUser.js';
import Api from '@/api/index';
export default {
    setup() {
        provide('user', useUser())
        Api.uniModel('kitpack').findAll().then();
    }
}
</script>

<style lang="less">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  color: #404454;
  background: #f5f6fa;
  min-height: 100vh;
}
.ant-slider-handle {
    background: #999 !important;
    border: none !important;
    width: 10px !important;
    height: 10px !important;
    margin-top: -3px !important;
}
.ant-slider-track {
    background: #999 !important;
}
.flex { display: flex;}
.flex1 { flex: 1;}
.flex2 { flex: 2;}
.flex3 { flex: 3;}
.flex4 { flex: 4;}
.flex5 { flex: 5;}
.flex-align-center {
    display: flex;
    align-items: center;
}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.text-center { text-align: center; }
.strong { font-weight: bold; }
.cl_404454 { color: #404454; }

.generate-opa(100);
.generate-opa(@n, @i: 5) when (@i =< @n) {
    .opa@{i} {
        opacity: @i / @n !important;
    }
    .generate-opa(@n, @i + 5);
}

.generate-margin(100);
.generate-margin(@n, @i: 1) when (@i =< @n) {
    .mt@{i} { margin-top: @i * 2px !important; }
    .mb@{i} { margin-bottom: @i * 2px !important; }
    .mlr@{i} {
        margin-left: @i * 2px !important;
        margin-right: @i * 2px !important;
    }
    .generate-margin(@n, @i + 1);
}


.reading1-article {
    .ant-select-selector {
        border: none !important;
        border-bottom: 1px solid #ddd !important;
        border-radius: 0 !important;
        padding: 0 !important;
        padding-right: 0 !important;
        box-shadow: none !important;
        .ant-select-selection-item {
            color: #000;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
        }
    }
    .ant-select-arrow {
        right: 0 !important;
    }
}

.comp-user-container {
    .ant-modal-body {
        padding: 0 !important;
    }
}
</style>
