import axios from 'axios'
import CryptoJS from 'crypto-js';

const SecretId = 'AKIDeqimwr5p5f0h7vllWci6gzapr6n8adcmah6g'; // 密钥对的 SecretId
const SecretKey = 'KGCzYkkMHl1c6dx28kPQ9h8vhyqWpd9miTZYyk5U'; // 密钥对的 SecretKey

const genAuthorization = (dateTime, source) => {
    var auth = `hmac id="${SecretId}", algorithm="hmac-sha1", headers="x-date source", signature="`;
    var signStr = `x-date: ${dateTime}\nsource: ${source}`;
    var sign = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA1(signStr, SecretKey))
    return `${auth}${sign}"`
}

const http = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? 'https://api.amathclass.cn' : 'https://api.amathclass.cn', // 用户 API 的访问路径
    timeout: 10000,
    withCredentials: true
});

// 请拦截
const source = 'st'; // 签名水印值，可填写任意值
http.interceptors.request.use(req => {
    const dateTime = new Date().toUTCString();
    req.headers['Source'] = source;
    req.headers['X-Date'] = dateTime;
    req.headers['Authorization'] = genAuthorization(dateTime, source);
    return req;
}, error => Promise.reject(error))

// 响应拦截
http.interceptors.response.use(
    res => {
        if (res.status === 200 && res.data.success) return res.data.data;
        else return Promise.reject(res.data)
    },
    err => Promise.reject(err)
);

http.apiCloud = (params) => {
    return http.post('/release/newStiApp', params);
}

export default http;
