<template>
<div class="material-title">
    <div class="material-note">Questions {{material.qIndex}} {{ material.qsList.length >= 3 ? 'to' : 'and'}} {{material.qIndex + material.qsList.length - 1}} are based on the {{ materialType }} you have just heard.</div>
    <!-- <div class="mlr10"></div>
    <a-button type="dashed" size="small" @click="playMaterialAudio">播放音频</a-button>
    <div class="mlr10">-</div>
    <a-button type="dashed" size="small" @click="showoriginModal">查看原文</a-button> -->
</div>
</template>

<script>
export default {
    props: {
        material: { type: Object, default: () => ({})},
        materialType: { type: String, default: ''}
    },
    setup(props, { emit }) {
        const playMaterialAudio = () => emit('play');
        const showoriginModal = () => emit('showOrigin');
        return { playMaterialAudio, showoriginModal}
    }
}
</script>

<style lang="less" scoped>
@grey9: #434343;
@grey8_9: #4a4a4a;
@grey8: #595959;
.material-title {
    margin-top: 10px;
    display: flex;
    align-items: center;
    .material-note {
        color: @grey9;
        font-weight: bold;
    }
}
</style>