
export const deepClone = (obj) => {
    if (obj === null) return null;
    let clone = Object.assign({}, obj);
    Object.keys(clone).forEach(key => (clone[key] = typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key]));
    if (Array.isArray(obj)) {
        clone.length = obj.length;
        return Array.from(clone);
    }
    return clone;
}

export const optionIndexLabel = (index) => {
    if (index === 0) return 'A';
    if (index === 1) return 'B';
    if (index === 2) return 'C';
    if (index === 3) return 'D';
    if (index === 4) return 'E';
    if (index === 5) return 'F';
    if (index === 6) return 'G';
    if (index === 7) return 'H';
    if (index === 8) return 'I';
    if (index === 9) return 'J';
    if (index === 10) return 'K';
    if (index === 11) return 'L';
    if (index === 12) return 'M';
    if (index === 13) return 'N';
    if (index === 14) return 'O';
    if (index === 15) return 'P';
    if (index === 16) return 'Q';
    if (index === 17) return 'R';
    if (index === 18) return 'S';
    if (index === 19) return 'T';
};

export const uuid = () => ([1e3] + 4e3).replace(/[014]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));


export const randomIntegerInRange = (min, max) => Math.floor(Math.random() * (max - min)) + min;