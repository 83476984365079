
import { reactive } from 'vue'
import Api from '../api/index.js';
// import FingerprintJS from '@fingerprintjs/fingerprintjs'

import { randomIntegerInRange } from '@/util'
let webUUId = localStorage.getItem('webuid') || '';
if (!webUUId) {
    webUUId = `${randomIntegerInRange(0, 20)}${Date.now()}`
    localStorage.setItem('webuid', webUUId);
}

const openid = localStorage.getItem('_WEB_OPEN_') || '';
const user = reactive({
    webUUId,
    openid,
    coin: 0,
    buyedModules: []
})

const updateUser = () => {
    if (openid) {
        Api.uniModel('user').findOneBy({ openid }).then(res => {
            if (res) {
                console.log('登陆成功',res);
                user.openid = res.openid;
                user.nickName = res.nickName;
                user.coin = res.coin;
                user.isPro = res.isPro || false;
                user.buyedModules = res.buyedModules || [];
            }
        })
    }
}
updateUser();

// FingerprintJS.load().then(fp => {
//     fp.get().then(res => {
//         console.log('FingerprintJS', res);
//         user.webUUId = res.visitorId; 
//     })
// })

export default function() {
    return { user, updateUser };
}