<template>
	<main v-if="cetData && cetData.isOk" class="container">
        <!-- <div class="exam-title">{{ year }}年{{ month }}月大学英语四级真题（第{{ index }}套）</div> -->
        <!-- 写作部分 -->
        <section class="part">
            <section class="part-title">
                <div>Part I</div><div>Writing</div><div>（30 minutes）</div>
            </section>
            <section class="part-main">
                <section class="direction">
                    <span class="strong">Directions:</span> For this part, <span v-html="cetData.writing"></span> You should write at least 120 words but no more than 80 words
                </section>
            </section>
        </section>
        <!-- 听力部分 -->
        <section class="part">
            <section class="part-title">
                <div>Part II</div><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Listening Comprehension&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div>（25 minutes）</div>
            </section>
            <!-- <Aaudio v-if="!cetData.listenNote" :year="year" :month="month" :src="cetData.audioSrc" :startTime="startTime"/> -->
            <template v-if="!cetData.listenNote">
                <!-- section A -->
                <section class="part-main ">
                    <div class="main-section mt10">Section A</div>
                    <div class="direction"><span class="strong">Directions: </span> In this section, you will hear three news reports. At the end of each news report, you will hear two or three questions. Both the news report and then questions will be spoken only once. After you hear a question, you must choose the best answer from the four choices marked A) , B), C) and D). Then mark the corresponding letter on Answer Sheet 1 with a single line through the centre.</div>
                    <div v-for="(material, materialIndex) of cetData.listeningA" :key="materialIndex">
                        <MaterialHeader :material="material" materialType="news report" @play="playMaterialAudio(material.startTime)" @showOrigin="showoriginModal(material)" />
                        <div v-for="(q, quesIndex) of material.qsList" :key="q.uuid" class="q-item">
                            <div class="index">{{ material.qIndex + quesIndex }}.</div>
                            <div>
                                <a-radio-group v-model:value="cetData.listeningA[materialIndex].qsList[quesIndex].userAnswer" >
                                    <a-radio :style="radioStyle" :value="optionIndex" v-for="(option, optionIndex) of q.options" :key="option.id">
                                        <span :class="cetData.listeningA[materialIndex].qsList[quesIndex].userAnswer >= 0 ? (optionIndexLabel(optionIndex) === q.answer ? 'option-right' : (optionIndex === cetData.listeningA[materialIndex].qsList[quesIndex].userAnswer ? 'option-error' : {} )) : {}">{{ optionIndexLabel(optionIndex)}}) {{ option }}</span> 
                                    </a-radio>
                                </a-radio-group>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- section B -->
                <section class="part-main">
                    <div class="main-section">Section B</div>
                    <div class="direction"><span class="strong">Directions: </span> In this section, you will hear two long conversations. At the end of each conversation, you will hear four questions. Both the conversation and then questions will be spoken only once. After you hear a question, you must choose the best answer from the four choices marked A) , B), C) and D). Then mark the corresponding letter on Answer Sheet 1 with a single line through the centre.</div>
                    <div v-for="(material, materialIndex) of cetData.listeningB" :key="materialIndex">
                        <MaterialHeader :material="material"  materialType="conversation" @play="playMaterialAudio(material.startTime)" @showOrigin="showoriginModal(material)" />
                        <div v-for="(q, quesIndex) of material.qsList" :key="q.uuid" class="q-item">
                            <div class="index">{{ material.qIndex + quesIndex }}.</div>
                            <div>
                                <a-radio-group v-model:value="cetData.listeningB[materialIndex].qsList[quesIndex].userAnswer" >
                                    <a-radio :style="radioStyle" :value="optionIndex" v-for="(option, optionIndex) of q.options" :key="option.id">
                                        <span :class="cetData.listeningB[materialIndex].qsList[quesIndex].userAnswer >= 0 ? (optionIndexLabel(optionIndex) === q.answer ? 'option-right' : (optionIndex === cetData.listeningB[materialIndex].qsList[quesIndex].userAnswer ? 'option-error' : {} )) : {}">{{ optionIndexLabel(optionIndex)}}) {{ option }}</span> 
                                    </a-radio>
                                </a-radio-group>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- section C -->
                <section class="part-main">
                    <div class="main-section">Section C</div>
                    <div class="direction"><span class="strong">Directions: </span> In this section, you will hear three passages. At the end of each passage, you will hear three or four questions. Both the passage and the questions will be spoken only once. After you hear a question, you must choose the best answer from the four choices marked A) , B), C) and D). Then mark the corresponding letter on Answer Sheet 1 with a single line through the centre.</div>
                    <div v-for="(material, materialIndex) of cetData.listeningC" :key="materialIndex">
                        <MaterialHeader :material="material" materialType="passage" @play="playMaterialAudio(material.startTime)" @showOrigin="showoriginModal(material)" />
                        <div v-for="(q, quesIndex) of material.qsList" :key="q.uuid" class="q-item">
                            <div class="index">{{ material.qIndex + quesIndex }}.</div>
                            <div>
                                <a-radio-group v-model:value="cetData.listeningC[materialIndex].qsList[quesIndex].userAnswer" >
                                    <a-radio :style="radioStyle" :value="optionIndex" v-for="(option, optionIndex) of q.options" :key="option.id">
                                        <span :class="cetData.listeningC[materialIndex].qsList[quesIndex].userAnswer >= 0 ? (optionIndexLabel(optionIndex) === q.answer ? 'option-right' : (optionIndex === cetData.listeningC[materialIndex].qsList[quesIndex].userAnswer ? 'option-error' : {} )) : {}">{{ optionIndexLabel(optionIndex)}}) {{ option }}</span> 
                                    </a-radio>
                                </a-radio-group>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- <template v-for="(item, sectionIndex) of cetData.listening" :key="item.id">
                    <section class="part-main">
                        <div class="main-section">Section{{item.sectionIndex}}</div>
                        <div class="direction"><span class="strong">Directions: </span> {{ item.directions }}</div>
                        <div class="main-bd">
                            <div v-for="(material, materialIndex) of item.materialArr" :key="material.quesId">
                                <div class="material-title">
                                    <div class="material-note">Questions {{material.qIndex}} {{ material.qsList.length >= 3 ? 'to' : 'and'}} {{material.qIndex + material.qsList.length - 1}} are based on the {{ material.materialType }} you have just heard.</div>
                                    <div class="mlr10"></div>
                                    <a-button type="dashed" size="small" @click="playMaterialAudio(material.startTime)">播放音频</a-button>
                                    <div class="mlr10">-</div>
                                    <a-button type="dashed" size="small" @click="showoriginModal(material)">查看原文</a-button>
                                </div>
                                <div v-for="(q, quesIndex) of material.qsList" :key="material.quesId + quesIndex" class="q-item">
                                    <div class="index">{{ material.qIndex + quesIndex }}.</div>
                                    <div>
                                        <a-radio-group v-model:value="cetData.listening[sectionIndex].materialArr[materialIndex].qsList[quesIndex].userAnswer" >
                                            <a-radio :style="radioStyle" :value="optionIndex" v-for="(option, optionIndex) of q.options" :key="option.id">
                                                <span :class="cetData.listening[sectionIndex].materialArr[materialIndex].qsList[quesIndex].userAnswer >= 0 ? 
                                                    (optionIndexLabel(optionIndex) === q.answer ? 'option-right' : (optionIndex === cetData.listening[sectionIndex].materialArr[materialIndex].qsList[quesIndex].userAnswer ? 'option-error' : {} )) 
                                                    : {}">{{ optionIndexLabel(optionIndex)}}) {{ option }}</span> 
                                            </a-radio>
                                        </a-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </template> -->
            </template>
            <section v-else class="mt15" style="font-size: 20px; ">{{ cetData.listenNote }}</section>
        </section>
        <!-- 阅读部分 -->
        <section class="part">
            <section class="part-title">
                <div>Part III</div><div>Reading Comprehension</div><div>（40 minutes）</div>
            </section>
            <!-- 阅读一：选词填空 -->
            <section class="part-main">
                <div class="main-section">Section A</div>
                <div class="direction"><span class="strong">Directions: </span>In this section, there is a passage with ten blanks. You are required to select one word for each blank from a list of choices given in a word bank following the passage. Read the passage through carefully before making your choices. Each choice in the bank is identified by a letter. Please mark the corresponding letter for each item on Answer Sheet 2 with a single line through the centre. You may not use any of the words in the bank more than once.</div>
                <div class="main-bd">
                    <section class="article reading1-article">
                        <div class="p" v-for="(p, index) of cetData.reading1.article" :key="index">
                            <template v-for="(word, j) of p" :key="j">
                                <a-select v-if="/^\d{1,2}\[_\]$/.test(word)" v-model:value="reading1Form[parseInt(word)]" style="width: 115px; margin: 0 5px;">
                                    <a-select-option :value="option" v-for="(option, index) of cetData.reading1.options" :key="index">
                                        <span :class="isShowReading1Answer ? (reading1Form[parseInt(word)] === cetData.reading1.answers[parseInt(word)] ? 'option-right' : 'option-error') : ''">{{option}}</span>
                                    </a-select-option>
                                </a-select>
                                <span v-else>{{ word }}</span>
                                <span>&nbsp;</span>
                            </template>
                        </div>
                    </section>
                    <section class="options-wrap reading1-options">
                        <div class="options">
                            <div class="option" v-for="(option, index) of cetData.reading1.options" :key="index">{{ optionIndexLabel(index)}}. {{ option }}</div>
                        </div>
                        <div class="flex-center mt10">
                            <a-button type="dashed" @click="toggleShowReading1Answer">{{ isShowReading1Answer ? '隐藏答案' : '显示答案'}}</a-button>
                            <div v-show="isShowReading1Answer" class="mlr10"> {{ cetData.reading1.answers.join(' ,  ')}}</div>
                        </div>
                    </section>
                </div>
            </section>
            <!-- 阅读二：快速阅读 -->
            <section class="part-main">
                <div class="main-section">Section B</div>
                <div class="direction"><span class="strong">Directions: </span>In this section, you are going to read a passage with ten statements attached to it. Each statement contains information given in one of the paragraphs. Identify the paragraph from which the information is derived. You may choose a paragraph more than once. Each paragraph is marked with a letter. Answer the questions by marking the corresponding letter on Answer Sheet 2</div>
                <div class="main-bd">
                    <section class="article">
                        <div class="p" v-for="(p, index) of cetData.reading2.article" :key="index">{{ p }}</div>
                    </section>
                    <section class="options reading2-options">
                        <div class="option" v-for="(option, qIndex) of cetData.reading2.qsList" :key="qIndex">
                            <a-select v-model:value="reading2Form[qIndex]" style="width: 55px; margin-right: 8px">
                                <a-select-option :value="index" v-for="(option, index) of cetData.reading2.article" :key="index">
                                    <span :class="isShowReading2Answer ? (optionIndexLabel(reading2Form[qIndex]) === cetData.reading2.answers[qIndex] ? 'option-right' : 'option-error') : ''">{{ optionIndexLabel(index) }}</span>
                                </a-select-option>
                            </a-select>
                            <div class="option-title">{{ option }}</div>
                        </div>
                        <div class="flex-align-center mt10">
                            <a-button type="dashed" @click="toggleShowReading2Answer">{{ isShowReading2Answer ? '隐藏答案' : '显示答案'}}</a-button>
                            <div v-show="isShowReading2Answer" class="mlr10"> {{ cetData.reading2.answers.join(' ')}}</div>
                        </div>
                    </section>
                </div>
            </section>
            <!-- 阅读三：仔细阅读 -->
            <section class="part-main">
                <div class="main-section">Section C</div>
                <div class="direction"><span class="strong">Directions: </span>There are 2 passages in the section, Each passage is fillowed by some questions or unfinished statements, For each of them there are four choices marked A, B, C and D, You shoulded decide on the best choice and mark the corresponding letter on Andwer Sheet 2 with a single line throught the centre.</div>
                <div class="main-bd">
                    <div v-for="(passage, index) of cetData.reading3" :key="index">
                        <div class="article">
                            <div class="p" v-for="(p, pIndex) of passage.article" :key="pIndex">{{ p }}</div>
                        </div>
                        <div class="mt5" v-for="(q, qIndex) of passage.qsList" :key="q">
                            <div class="title">{{ q.title }}</div>
                            <a-radio-group v-model:value="cetData.reading3[index].qsList[qIndex].userAnswer">
                                <a-radio :style="radioStyle" :value="optionIndex" v-for="(option, optionIndex) of q.options" :key="optionIndex">
                                    <span :class="cetData.reading3[index].qsList[qIndex].userAnswer >= 0 ? (optionIndexLabel(optionIndex) === q.answer ? 'option-right' : optionIndex === cetData.reading3[index].qsList[qIndex].userAnswer ? 'option-error' : '') : ''">{{ optionIndexLabel(optionIndex)}}. {{ option }}</span>
                                </a-radio>
                            </a-radio-group>
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <!-- 翻译部分 -->
        <section class="part">
            <section class="part-title">
                <div>Part IV</div><div>Translation</div><div>（30 minutes）</div>
            </section>
            <section class="part-main">
                <div class="direction"><span class="strong">Directions: </span>{{ cetData?.translation?.sectionTitle}}</div>
                <div class="mt10">
                    <div v-for="(text, index) of cetData?.translation?.text" :key=index>{{ text }}</div>
                </div>
            </section>
        </section>
        <div style="margin-top: 200px"></div>
        <a-divider>大学英语四级真题到此结束，祝同学考试顺利</a-divider>

        <!-- 听力原文弹窗 -->
        <a-modal title="" v-model:visible="originModal.show" :footer="null" :width="980" :style="{top: '10vh', height: '90vh', boxSizing: 'border-box'}">
            <a-spin :spinning="originModal.isLoading">
                <section class="text-arr">
                    <div v-for="(item, index) of originModal.originText" :key="index" :class="curTime > item.timeRange[0] && curTime <= item.timeRange[1] ? 'active' : ''">
                        <div class="item-en">{{ item.en }}</div>
                        <div class="item-cn">{{ item.cn }}</div>
                    </div>
                </section>
            </a-spin>
        </a-modal>
    </main>
    <main v-if="cetData.isMaking" class="container">
        <div>制作中...</div>
    </main>
    <main v-if="cetData.none" class="container">
        <div>{{cetData.noneNote}}</div>
    </main>
</template>

<script>
// import Api from '@/api/index';
import cet4Data from '@/data/cet4/index';
import { reactive, ref, watch } from 'vue';
// import Aaudio from './Aaudio';
import MaterialHeader from './MaterialHeader';
import { deepClone, optionIndexLabel } from '@/util/index';
export default {
    name: 'Index',
    components: { MaterialHeader },
    props: {
        year: { type: Number, default: 2020 },
        month: { type: Number, default: 12 },
        index: { type: Number, default: 1 }
    },
    setup(props = {}) {
        const isShowReading1Answer = ref(false);
        const isShowReading2Answer = ref(false);
        const toggleShowReading2Answer = () => isShowReading2Answer.value = !isShowReading2Answer.value; 
        const toggleShowReading1Answer = () => isShowReading1Answer.value = !isShowReading1Answer.value; 
        const cetData = reactive(deepClone(cet4Data(props.year, props.month, props.index) || { isOk: false }));
        watch(props, (newProps) => {
            const newData = deepClone(cet4Data(newProps.year, newProps.month, newProps.index) || {});
            cetData.isOk = newData.isOk || false;
            cetData.noneNote = newData.noneNote || false;
            cetData.none = newData.none || false;
            cetData.isMaking = newData.isMaking || false;
            cetData.audioSrc = newData.audioSrc || "";
            cetData.writing = newData.writing || "" ;
            cetData.listenNote = newData.listenNote || '';
            cetData.listeningA = newData.listeningA || {};
            cetData.listeningB = newData.listeningB || {};
            cetData.listeningC = newData.listeningC || {};
            cetData.reading1 = newData.reading1 || {};
            cetData.reading2 = newData.reading2 || {};
            cetData.reading3 = newData.reading3 || {};
            cetData.translation = newData.translation || {};
            isShowReading1Answer.value = false;
            isShowReading2Answer.value = false;
        })
        const radioStyle = reactive({ display: 'flex', alignItems: 'center', color: '#000', fontSize: '18px', lineHeight: '30px' });
        const reading1Form = reactive(['', '', '', '', '', '', '', '', '', '']);
        const reading2Form = reactive({})
        const originModal = reactive({
            isLoading: false,
            show: false,
            curTime: 0,
            originText: [],
            quesId: ''
        })
        const showoriginModal = material => {
            originModal.show = true;
            // originModal.isLoading = true;
            originModal.quesId = material.quesId;
            originModal.curTime = 0;
            originModal.originText = material.originText;
            // Api.getOriginByQuesId(material.quesId).then(res => {
            //     originModal.isLoading = false;
            //     originModal.originText = res.textArr;
            //     console.log(res);
            // })
        }
        const startTime = ref(0);
        const playMaterialAudio = time => {
            console.log(startTime.value === time, startTime.value, time);
            if (startTime.value === time) {
                startTime.value = time + Math.random() * 0.5;
            } else startTime.value = time;
        }
        
        return { 
            isShowReading1Answer, toggleShowReading1Answer, 
            isShowReading2Answer, toggleShowReading2Answer,
            startTime, radioStyle, cetData, optionIndexLabel, reading1Form, reading2Form, originModal, showoriginModal, playMaterialAudio };
    }
}
</script>

<style lang="less" scoped>
@grey12: #000;
@grey11: #141414;
@grey11: #1f1f1f;
@grey10: #262626;
@grey9: #434343;
@grey8_9: #4a4a4a;
@grey8: #595959;
@grey7: #8c8c8c;
@grey6: #bfbfbf;
@grey5_6: #ccc;
@grey5: #d9d9d9;
@grey4_5: #ededed;
@grey4: #f0f0f0;
@grey3: #f5f5f5;
@grey2: #fafafa;
@grey1: #fff;
.container {
    position: relative;
    padding: 0 64px 20px;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    color: @grey11;
    font-size: 17px;
    text-align: left;
    line-height: 1.6;
    font-family: 'Times New Roman', Times, serif;
    .exam-title {
        text-align: center;
        color: @grey10;
        font-size: 26px;
        font-weight: bold;
    }
}
.part {
    margin-top: 40px;
    .part-title {
        color: @grey9;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        max-width: 80%;
        margin-bottom: 10px;
    }
    .part-main {
        .main-section { font-size: 20px; font-weight: bold; margin: 30px 0 10px; }
        .direction { 
            font-size: 17px;
            font-style: italic;
            color: @grey7;
        }
        .article {
            line-height: 1.9;
            font-size: 17px;
            margin-top: 15px;
            .p {
                margin-bottom: 10px;
            }
        }
        .q-item {
            display: flex;
            padding: 5px 0 5px 0;
            // font-size: 18px;
            align-items: center;
            .index {
                align-self: flex-start;
                margin-right: 4px;
            }
            .option {
                margin-bottom: 2px;
            }
        }
    }
}
.option-right { color: #02AF42; font-weight: bold;}
.option-error { color: #ff4d4f; font-weight: bold;}
.material-title {
    margin-top: 10px;
    display: flex;
    align-items: center;
    .material-note {
        color: @grey9;
        font-weight: bold;
    }
}
.reading1-article {
    .p {
        display: flex;
        flex-wrap: wrap;
        word-break: keep-all;
        .empty {
            
        }
    }
}
.reading1-options {
    border: 1px solid #ddd;
    padding: 25px;
    padding-right: 0;
    margin-top: 25px;

    .option {
        width: 25%;
        display: inline-block;
    }
}
.reading2-options {
    margin-top: 20px;
    line-height: 2;
    .option {
        display: flex;
        align-items: center;
        .option-title {
            flex: 1;
        }
    }
}

.text-arr {
    flex: 4;
    height: 80vh;
    box-sizing: border-box;
    padding-bottom: 160px;
    overflow: scroll;
    // max-height: 70vh;
    box-sizing: border-box;
    padding-right: 15px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 17px;
    .active {
        font-weight: bold;
        .item-cn {
            color: #d94366;
            opacity: .9;
        }
        .item-en {
            color: #d94366;
        }
    }
    .item-cn {
        font-size: 15px;
        color: #999;
        margin-bottom: 8px;
    }
}
.writing-strong { font-weight: bold; color: #4a4a4a; }
</style>
