import cet4_2020121 from './2020-12-1';
import cet4_2020122 from './2020-12-2';
import cet4_2020123 from './2020-12-3';

import cet4_202091 from './2020-9-1';
import cet4_202092 from './2020-9-2';


import cet4_202071 from './2020-7-1';

import cet4_2019121 from './2019-12-1';
import cet4_2019122 from './2019-12-2';
import cet4_2019123 from './2019-12-3';
import cet4_201961 from './2019-6-1';
import cet4_201962 from './2019-6-2';
import cet4_201963 from './2019-6-3';

const cet4_202093 = { none: true, noneNote: '2020年9月大学英语四级考试 全国仅考二套试卷，无第3套试卷' };
const cet4_202072 = { none: true, noneNote: '2020年7月大学英语四级考试 全国仅考一套试卷，无第2套第3套试卷' };
const cet4_202073 = { none: true, noneNote: '2020年7月大学英语四级考试 全国仅考一套试卷，无第2套第3套试卷' };
const data = {
    cet4_2020121,
    cet4_2020122,
    cet4_2020123,
    
    cet4_202091,
    cet4_202092,
    cet4_202093,

    cet4_202071,
    cet4_202072,
    cet4_202073,

    cet4_2019121,
    cet4_2019122,
    cet4_2019123,
    cet4_201961,
    cet4_201962,
    cet4_201963
}
export default (year, month, index) => {
    console.log(year, month, index);
    return data[`cet4_${year}${month}${index}`]
}