import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import { Row, Card, Select, Radio, Button, Spin, Divider, Slider, Modal, Layout, Menu } from 'ant-design-vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import TplCet4 from './components/TplCet4.vue';

import { 
    MenuUnfoldOutlined, MenuFoldOutlined,
    AppstoreOutlined,
    InboxOutlined,
    DesktopOutlined,
    PieChartOutlined,
    PauseCircleOutlined,
    UploadOutlined, PlayCircleOutlined, DoubleLeftOutlined, DoubleRightOutlined,
    UserOutlined, BorderInnerOutlined, OrderedListOutlined, EditOutlined
} from '@ant-design/icons-vue';
const app = createApp(App);

// app.use(Radio);
// app.use(Button);
// app.use(Spin);
// app.use(Divider);
// app.use(Slider);
// app.use(Select);
// app.use(Card);
// app.use(Modal);
// app.use(Layout);
// app.use(Menu);
// app.use(Row);
app.use(Antd);


app.use(router)
    .component('MenuUnfoldOutlined', MenuUnfoldOutlined)
    .component('MenuFoldOutlined', MenuFoldOutlined)
    .component('AppstoreOutlined', AppstoreOutlined)
    .component('InboxOutlined', InboxOutlined)
    .component('UploadOutlined', UploadOutlined)
    .component('PieChartOutlined', PieChartOutlined)
    .component('DesktopOutlined', DesktopOutlined)
    .component('PlayCircleOutlined', PlayCircleOutlined)
    .component('PauseCircleOutlined', PauseCircleOutlined)
    .component('DoubleLeftOutlined', DoubleLeftOutlined)
    .component('DoubleRightOutlined', DoubleRightOutlined)
    .component('UserOutlined', UserOutlined)
    .component('BorderInnerOutlined', BorderInnerOutlined)
    .component('OrderedListOutlined', OrderedListOutlined)
    .component('EditOutlined', EditOutlined)
    .component('TplCet4', TplCet4)
    .mount('#app')

const isDev = process.env.NODE_ENV === 'development';

if (!isDev) {
    var _hmt = _hmt || [];
    (function() {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?d80d6a240b550d19f4d3b5e49ecdeb52";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
    })();
}


