export default {
    isOk: false,
    isMaking: true,
    writing: 'you are allowed 30 minutes to write on the topic <span class="cl_404454 strong">Changes in the Way of Transportation.</span>',
    trans: '生活在中国不同地区的人们饮食多种多样。北方人主要吃面食,南方人大多吃米饭。在沿海地区,海鲜和淡水水产品在人们饮食中占有相当大的比例,而在其他地区人们的饮食中,肉类和奶制品更为常见。四川、湖南等省份的居民普遍爱吃辛辣食物,而江苏和浙江人更喜欢甜食。然而,因为烹饪方式各异,同类食物的味道可能会有所不同。',
    listeningA: [
        {
            qIndex: 1,
            sectionTitle: 'In this section, you will hear three news reports. At the end of each news report, you will hear two or three questions. Both the news report and then questions will be spoken only once. After you hear a question, you must choose the best answer from the four choices marked A) , B), C) and D). Then mark the corresponding letter on Answer Sheet 1 with a single line through the centre',
            qsList: [
                {
                    answer: '',
                    options: ['A deadly fish has been spotted in the Mediterraneanwaters.', 'Invasive species are driving awaycertain native species.', 'The Mediterranean is a natural habitatof Devil Firefish.', 'Many people have been attacked by DevilFirefish.'],
                },
                {
                    options: ['It could add to greenhouse emissions.', 'It could disrupt the food chains there.', 'It could pose a threat to other marinespecies.', 'It could badly pollute the surroundingwaters.'],
                },
            ].map(i => ({...i, userAnswer: -1})),
        },
        {
            qIndex: 3,
            sectionTitle: 'In this section, you will hear three news reports. At the end of each news report, you will hear two or three questions. Both the news report and then questions will be spoken only once. After you hear a question, you must choose the best answer from the four choices marked A) , B), C) and D). Then mark the corresponding letter on Answer Sheet 1 with a single line through the centre',
            qsList: [
                {
                    answer: '',
                    options: ['cars will not be allowed to enter thecity.', 'About half of its city center will beclosed to cars.', 'Buses will be the only vehicles allowedon its streets.', 'Pedestrianswillhavefreeaccesstothecity.'],
                },
                {
                    options: ['The rising air pollutionin Paris.', 'The worsening global warming.', 'The ever-growing cost of petrol.', 'The unbearable traffic noise.'],
                },
            ].map(i => ({...i, userAnswer: -1})),
        },
        {
            qIndex: 5,
            sectionTitle: 'In this section, you will hear three news reports. At the end of each news report, you will hear two or three questions. Both the news report and then questions will be spoken only once. After you hear a question, you must choose the best answer from the four choices marked A) , B), C) and D). Then mark the corresponding letter on Answer Sheet 1 with a single line through the centre',
            qsList: [
                {
                    answer: '',
                    options: ['Many of his possessions were stolen.', 'His house was burnt down in a fire.', 'His fishing boat got wrecked on a rock.', 'His good luck charm sank into the sea.'],
                },
                {
                    options: ['Change his fishing locations.', 'Find a job in a travel agency.', 'Spend a few nights on a small island.', 'Sell the pearl he had kept for years.'],
                },
                {
                    options: ['A New Year museum.', 'The largest pearl in the world weighs.', 'His monstrous pearl was extremelyvaluable.', 'His pearl could be displayed in amuseum.'],
                },
            ].map(i => ({...i, userAnswer: -1})),
        },
    ],
    listeningB: [
        {
            qIndex: 8,
            quesId: '3dfe72d65fa67199006276a74c112970',
            startTime: 472,
            qsList: [
                {
                    userAnswer: -1,
                    answer: 'C',
                    options: ['It boasts a fairly long history.', ' It produces construction materials.', ' It has 75 offices around the world.', 'It has over 50 business partners.'],
                },
                {
                    userAnswer: -1,
                    answer: 'B',
                    options: ['It has about 50 employees.', 'It was started by his father.', 'It has a family business.', 'It is over 100 years old.'],
                },
                {
                    userAnswer: -1,
                    answer: 'D',
                    options: ['Shortage of raw material supply.', 'Legal disputes in many countries.', 'Outdated product design.', 'Loss of competitive edge.'],
                },
                {
                    userAnswer: -1,
                    answer: 'A',
                    options: ['Conducting a financial analysis for it.', 'Providing training for its staffmembers.', 'Seeking new ways to increase is exports.', 'Introducing innovative marketingstrategies.'],
                },
            ],
        },
        {
            qIndex: 12,
            quesId: '3dfe72d65fa67199006276a74c112970',
            startTime: 472,
            qsList: [
                {
                    userAnswer: -1,
                    answer: 'B',
                    options: ['She is a real expert at housedecorations.', 'She is well informed about the designbusiness.', 'She is attracted by the color of thesitting room.', 'She is really impressed by the man’s house.'],
                },
                {
                    userAnswer: -1,
                    answer: 'D',
                    options: ['From his younger brother Greg.', 'From home design magazines.', 'From a construction businessman.', 'From a professional interior designer.'],
                },
                {
                    userAnswer: -1,
                    answer: 'C',
                    options: ['The effort was worthwhile.', 'The style was fashionable.', 'The cost was affordable.', 'The eft was unexpected.'],
                },
                {
                    userAnswer: -1,
                    answer: 'D',
                    options: ['She’d like him to talk with Jonathanabout a new project.', 'She wants him to share his renovationexperience with her', 'She wants to discuss the housedecoration budget with him.', 'She’d like to show him around hernewly-renovated house. '],
                },
            ],
        },
    ],
    listeningC: [
        {
            materialType: 'passage',
            qIndex: 16,
            startTime: 906,
            quesId: '38597c165fa67c8a0071093577a407cf',
            qsList: [
                {
                    userAnswer: -1,
                    answer: 'A',
                    options: ['Providing routine care for smallchildren.', 'Paying hospital bills for emergencycases.', 'Doing research on ear, nose and throatdiseases.', 'Removing objects from patients’ nosesand ears.'],
                },
                {
                    userAnswer: -1,
                    answer: 'C',
                    options: ['Many children like to smell things theyfind or play with.', 'Many children like to put foreignobjects in their mouths.', 'Five-to nine-year-olds are the moslikely to put things in their ears.', 'Children aged one to four a often morecurious than older children.'],
                },
                {
                    userAnswer: -1,
                    answer: 'B',
                    options: ['They tend to act out of impulse.', 'They want to attract attentions.', 'They are unaware of the potential risks.', 'They are curious about these body parts. '],
                },
            ],
        },
        {
            materialType: 'passage',
            qIndex: 19,
            startTime: 906,
            quesId: '38597c165fa67c8a0071093577a407cf',
            qsList: [
                {
                    userAnswer: -1,
                    answer: 'A',
                    options: [' It paid for her English lessons.', ' It gave her a used bicycle.', ' It delivered her daily necessities.', ' It provided her with physical therapy.'],
                },
                {
                    userAnswer: -1,
                    answer: 'C',
                    options: ['Expanding bike-riding lessons.', 'Asking local people for donations.', 'Providing free public transport.', 'Offering walking tours to visitors.'],
                },
                {
                    userAnswer: -1,
                    answer: 'B',
                    options: ['It is a language school.', 'It is a charity organization.', 'It is a counseling center.', 'It is a sports club. '],
                },
            ],
        },
        {
            materialType: 'passage',
            qIndex: 22,
            startTime: 906,
            quesId: '38597c165fa67c8a0071093577a407cf',
            qsList: [
                {
                    userAnswer: -1,
                    answer: 'D',
                    options: ['How mice imitate human behavior a space.', 'How low gravity affects the human body.', 'How mice interact in a new environment.', 'How animals deal with lack of gravity.'],
                },
                {
                    userAnswer: -1,
                    answer: 'A',
                    options: ['They were not use to the low-gravity environment.', 'They found it difficult to figure outwhere they were.', 'They found the space in the cage toosmall to stay in.', 'They were not sensitive to the changedenvironment.'],
                },
                {
                    userAnswer: -1,
                    answer: 'D',
                    options: ['They tried every thing possible toescape from the cage.', 'They continued to behave as they did inthe beginning.', 'They already felt at home in the newenvironment.', 'They had found a lot more activities toengage in.'],
                },
                {
                    userAnswer: -1,
                    answer: 'B',
                    options: ['They repeated their activities everyday.', 'They behaved as if they were on Earth.', 'They begin to eat less after some time.', 'They changed their routines in space.'],
                },
            ],
        },
    ],
    reading1: {
        article: [
            "Trust is fundamental to lie. If you cannot trust in anything,life becomes intolerable a constant battle against paranoia and looming disaster.You can't have relationships without trust ,let alone good ones. Intimacy depends on it.I suspect more marriages are wrecked by lack of trust than by actual infidelity. The partner who can't trust the other not to betray him or her will either drive them away or force them into some real or assumed act of faithlessness.".split(' '),
            "In the workplace too, trust is 0[_] . An organization without trust will be full of fear and 1[_] . If you work for a boss who doesn't trust their employees to do things right, you'll have a 2[_] time. They'll be cheeking up on you all the time, correcting ‘mistakes’ and 3[_] reminding you to do this or that. Colleagues who don't trust one another will need to spend more time 4[_] their backs than doing any useful work.".split(' '),
            "Organizations are always trying to cut costs. Think of all the additional tasks caused bt lack of trust. Audit(审计) departments only exist because of it. Companies kepp large volumes of 5[_] because they don't trust their suppliers, their contractors or their cursomers. Probably more than half of all administrative work is only there because of an ever-existing sense that “you can't trust anyone these days.” If even a small part of such valuesless work could be 6[_] , the savings would run into millions of dollars.".split(' '),
            "All this extra work we 7[_] onto ourselves because we don't trust people——the cheeking, following through, doing things ourselves because we don’t believe others will do them 8[_] ——or at all. If you took all that away, how much extra time would we suddenly find in our day? How much of our work 9[_] would disappear?".split(' '),
        ],
        answers: [],
        options: ['constantly', 'credible', 'essential', 'exploring', 'gather', 'load', 'miserable', 'pressure', 'properly', 'records', 'removed', 'stacks', 'suspicion', 'tracked', 'watching'],
    },

    reading2: {
        title: 'Doctor’s orders: Let children just play',
        article: [
            '【A】This is the land of opportunity. If that weren’t already implied by the landscape-rolling green hills, palm trees, sum-kissed flowers——then it’s evident in the many stories of people who grew up poor in these sleepy neighborhoods and rose to enormous cuccess. People like Tri Tran, who fled Vietnam on a boat in 1986 showed up in San Jose with nothing, made it to MIT and then founded the food-delivery start-up Munchery, which is valued at $300 million.',
            '【B】Indeed, data suggests that this is one of the best places to grow up poor in America. A child born in the early 1980s into a low-income family in San Jose had a 12.9 percent chance of becoming a high earner as an adult, according to a landmark study released in 2014 by the economist Raj Chetty and his colleagues from Harvard and Berkeley. That number-12.9 percent-may not seem remarkable, but it was: Kids in San Jose whose families fell in the bottom quintile(五分位数) of income nationally had the best shot in the country at. reaching the top quintile.',
            '【C】By contrast, just 4.4 percent of poor kids in Charlotte moved up to the top; in Detroit the figure was 5.5 percent. San Jose had social mobility comparable to Denmark’s and Canada’s and higher than other progressive cities such as Boston and Minneapolis.',
            '【D】The reasons kids in San Jose performed so well might seem obvious. Some of the world’s most innovative companies are located here, providing opportunities such as the one seized by a 12-year-old Mountain View resident named Steve Jobs when he called William Hewlett to ask for spare parts and subsequently received a summer job. This is a city of immigrants--38 percent of the city’s population today is foreign-born-and immigrants and their children have historically experienced significant upward mobility in America. The city has long had a large foreign-born population (26.5 percent in 1990),leading to broader diversity, which, the Harvard and Berkeley economists say, is a good predictor of mobility.',
            '【E】Indeed, the streets of San Jose seem, in some ways, to embody the best of America. It’s possible to drive in a matter of minutes from sleek(光亮的) office towers near the airport where people pitch ideas to investors, to single-family homes with orange trees in their yards, or to a Vietnamese mall The libraries here offer programs in 17 languages, and there are areas filled with small businesses owned by Vietnamese immigrants, Mexican immigrants, Korean immigrants, and Filipino immigrants, to name a few.',
            '【F】But researchers aren’t sure exactly why poor kids in San Jose did so well The city has a low prevalence of children growing up in single-parent families, and a low levelof concentrated poverty, both factors that usually mean a city allows for good intergenerational mobility. But San Jose also performs poorly on some of the measures correlated with good mobility. It is one of the most unequal places out of the 74l that the researchers measured, and it has high degrees of racial and economic segregation (隔离). Its schools underperform based on how much money there is in the area, said Ben Scuderi, a predoctoral fellow at the Equality of Opportunity Project at Harvard, which uses big data tostudy how to improve economic opportunities for low-income children. “There’s a lot going on here which we don’t totally understand," he said. “It’s interesting, because it kind of defies our expectations."',
            '【G】The Chetty data shows that neighborhoods and places mattered for children born in the San Jose area of the 1980s. Whether the city still allows for upward mobility of poor kids today, though, is up for debate. Some of the indicators such as income inequality; measured by the Equality of Opportunity Project for the year 2000, have only worsened in the past 16 years.',
            '【H】Some San Jose residents say that as inequality has grown in recent years, upward mobility has become much more difficult to achieve. As Silicon Valley has become home to more successful companies, the flood of people to the area has caused housing prices to skyrocket. By most measures, San Jose is no longer a place where low-income, or even middle-income families, can afford to live. Rents in San Jose grew 42.6 percent between 2006 and 2014, which was the largest increase in the country during that time period. The city has a growing homelessness problem, which it tried.to address by shutting down “The Jungle," one of the largest homeless encampments(临时住地)in the nation, in 2014. Inequality is extreme. The Human Development Index-a measure of life expectancy, education and per capita(人均的)income-gives East San Jose a score of 4.85 out of 10, while nearby Cupertino, where Apple’s headquarters sits, receives a 9.26. San Jose used to have a happy mix of factors--cheap housing, closeness to a rapidly developing industry, tightly-knit immigrant communities-that together opened up the possibility of prosperity for even its poorest residents. But in recent years, housing prices have skyrocketed, the region’s rich and poor have segregated, and middle-class jobs have disappeared. Given this, the future for the region’s poor doesn’t look nearly as bright as it once did.',
            '【I】Leaders in San Jose are determined to make sure that the city regains its status as a place where even poor kids can access the resources to succeed. With Silicon Valley in its backyard, it certainly has the chance to do so. “I think there is a broad consciousness in the Valley that we can do better than to leave thousands of our neighbors behind through a period of extraordinary success," San Jose Mayor Sam Liccardo said.',
            '【J】But in today’s America-a land of rising inequality, increasing segregation, and stagnating(不增长的) middle-class wages- can the San Jose region really once again become a place of opportunity?',
            '【K】The idea that those at the bottom can rise to the top is central to America’s ideas about itself. That such mobility has become more difficult in San Jose raises questions about the endurance of that foundational belief. After all, if the one-time land of opportunity can’t be fixed, what does that say for the rest of America?',
        ],
        qsList: [
            '36.According to some people living in San Jose, it has become much harder for the poor to get ahead due to the increased inequality.',
            '37.In American history, immigrants used to have a good chance to move upward in society.',
            '38.If the problems of San Jose can’t be solved, one of America’s fundamental beliefs about itself can be shaken.',
            '39.San Jose was among the best cities in America for poor kids to move up the social ladder.',
            '40.Whether poor kids in San Jose today still have the chance to move upward is questionable.',
            '41.San Jose’s officials are resolved to give poor kids access to the resources necessary for success in life.',
            '42.San Jose appears to manifest some of the best features of America.',
            '43.As far as social mobility is concerned, San Jose beat many other progressive cities in America.',
            '44.Due to some changes like increases in housing prices in San Jose, the prospects for its poor people have dimmed.',
            '45.Researchers do not have a clear idea why poor children in San Jose achieved such great success several decades ago.',
        ],
        'answers': ['H', 'E', 'L', 'G', 'D', 'I', 'C', 'K', 'F', 'B'],
    },
    reading3: [
        {
            article: [
                'Three children in every classroom have a diagnosable mental health condition. Half of these are behavioural disorders, while one third are emotional disorders such as stress, anxiety and depression, which often become outwardly apparent through self-harm. There was an astonishing 52 per cent jump in hospital admissions for children and young people who had harmed themselves between 2009 and 2015.',
                'Schools and teachers have consistently reported the scale of the problem since 2009. Last year, over half of teachers reported that more of their pupils experience mentalhealth problems than in the past. But teachers also consistently report how ill-equipped they feel to meet pupils’ mental health needs, and often cite a lack of training, expertise and support from the National HealthService (英国国家医疗服务体系)',
                'Part of the reason for the increased pressure on schools is that there are now fewer “early intervention(干预)” and low-level mental health services based in the community. Cuts to localauthority budgets since 2010 have resulted in a significant decline of these services, despite strong evidence of their effectiveness in preventing crises further down the line.',
                'The only way to break the pressures on both mental health services and schools is to reinvest in early intervention services inside schools.',
                'There are strong arguments for why schools are bestplaced to provide mental health services. Schools see young people more than any other service, which gives them a unique ability to get to hard-to-reach children and young people and build meaningful relationships with them over time. Recent studies have shown that children and young people largely prefer to see a counsellor in school rather than in an.outside environment. Young people have reported that for low-level conditions such as stress and anxiety, a clinical setting can sometimes be daunting(令人却步的).',
                'There are already examples of innovative schools which combine mental health and wellbeing provision with a strong academic curriculum. This will, though, require a huge culturalshift. Politicians, policymakers, commissioners and school leaders must be brave enough to make the leap towards reimagining schools as providers of health as well as education services.',
            ],
            qsList: [
                {
                    userAnswer: -1,
                    title: '46. What are teachers complaining about?',
                    answer: 'D',
                    options: ['There are too many students requiring special attention.', 'They are under too much stress counselling needy students.', 'Schools are inadequately equipped to implement any intervention.', 'They lack the necessary resources to address pupils’ mental problems.'],
                },
                {
                    userAnswer: -1,
                    title: '47. What do we lear from the passage about community health services in Britain?',
                    answer: 'B',
                    options: ['They have deteriorated due to budget cuts.', 'They facilitate local residents’ everyday lives.', 'They prove ineffective in helping mental patients.', 'They cover preventative care for the local residents.'],
                },
                {
                    userAnswer: -1,
                    title: '48. Where does the author suggest mental health services be placed?',
                    answer: 'C',
                    options: ['At home', 'At school', 'In Hospitals', 'In communities'],
                },
                {
                    userAnswer: -1,
                    title: '49. What do we learn from the recent studies?',
                    answer: 'B',
                    options: ['Students prefer to rely on peers to relieve stress and anxiety.', 'Young people are keen on building meaningful relationships.', 'Students are more comfortable seeking counselling in school.', 'Young people benefit from various kinds of outdoor activities.'],
                },
                {
                    userAnswer: -1,
                    title: '50. What does the author mean by a cultural shift (Line 2, Para.6)?',
                    answer: 'A',
                    options: ['Simplification of schools’ academic curriculums.', 'Parents’ involvement in schools’ policy-making.', 'A change in teachers’ attitudes to mental health.', 'A change in the conception of what schools are.'],
                },
            ],
        },
        {
            article: [
                "Picture this: You're ata movie theater food stand loading upon snacks. Youhavea choice of a small, medium or large soda. The small is $ 3.50 and the large is $5.50.It's a tough decision: The small size may not last you through the whole movie, but $5.50 for some sugary drink seems ridiculous. But there's a third option, a medium soda for $5.25. Medium may be the perfect amount of soda for you, but the large is only a quarter more. If you're like most people, you end up buying the large (and taking a bathroom break midshow).",
                "If you're wondering who would buy the medium soda, the answer is almost.no one. In fact, there's a good chance the marketing department purposely priced the medium soda asa decoy(诱饵),making you more likely to buy the large soda rather than the small.",
                'I have written about this peculiarity in human nature before with my friend Dan Ariely, who studied this phenomenon extensively after noticing pricing for subscriptions(订阅) to The Economist. The digital subscription was $ 59, the print subscription was $125, and the print plus digital subscription was also $125. No one in their right mind would buy the print subscription when you could get digital as well for the same price, so why was it even an option? Ariely ran an experiment and found that when only the two “real” choices were offered, more people chose the less-expensive digital subscription. But the addition of the bad option made people much more likely to choose the more expensive print plus digital option.',
                'Brain scientists call this effect “asymmetric dominance” and it means that people gravitate toward the choice nearest a clearly inferior option. Marketing professors call it the decoy effect, which is certainly easier to remember. Lucky for consumers, almost no one in the business community understands it.',
                "The decoy effect works because of the way our brains. assign value when making choices. Value is almost never absolute; rather, we decide an object's value relative to our other choices. If more options are introduced, the value equation changes.",
            ],
            qsList: [
                {
                    userAnswer: -1,
                    title: '51. Why does the author ask us to imagine buying foodin the movie theater?',
                    answer: 'D',
                    options: ['Toilustrate people’s peculiar shopping behavior.', 'To illustrate the increasing variety of snacks there.', 'To show how hard it can be to choose a drink there.', 'To show how popular snacks are among movie fans.'],
                },
                {
                    userAnswer: -1,
                    title: '52. Why is the medium soda priced the way it is?',
                    options: ['To attract more customers to buy it.', 'To show the price matches the amount.', 'To ensure customers drink the right amount of soda.', 'To make customers believe they are getting a bargain.'],
                },
                {
                    userAnswer: -1,
                    title: "53. What do we learn from Dan Ariely's experiment?",
                    answer: 'C',
                    options: ['Lower-priced goods attract more customers.', "The Economist's promotional strategy works.", "The Economist's print edition turns out to sell the best.", 'More readers choose the digital over the print edition.'],
                },
                {
                    userAnswer: -1,
                    title: '54. For what purpose is "the bad option" (Line 7, Para. 3) added?',
                    answer: 'B',
                    options: ['To cater to the peculiar needs of some customers.', 'To help customers to make more rational choices.', 'To trap customers into buying the more pricey item.', 'To provide customers with a greater variety of goods.'],
                },
                {
                    userAnswer: -1,
                    title: '55. How do we assess the value of a commodity, according to the passage?',
                    answer: 'D',
                    options: ['By considering its usefulness.', 'By taking its quality into account.', 'By comparing it with other choices.', 'By examining its value equation.'],
                },
            ],
        },
    ],

};
