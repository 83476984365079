import { createRouter, createWebHashHistory } from "vue-router";
// import Layout from "../components/Layout.vue";
import useUser from '@/store/useUser.js';

// const routes = [
//     {
//         path: "/",
//         name: "/",
//         component: Layout,
//         redirect: '/cet4',
//         // component: () => import("../views/Index.vue"),
//         children: [
//             {
//                 path: "/cet4",
//                 component: () => import("../views/Index.vue"),
//             },
//             {
//                 path: "/user",
//                 component: () => import("../views/User/User.vue"),
//                 meta: {
//                     needLogin: true,
//                 }
//             },
//             {
//                 path: '/listen',
//                 component: () => import('../views/Listen/Listen.vue')
//             },
//         ]
//     },
//     {
//         path: '/support',
//         component: () => import('../views/Support/Support.vue')
//     },
//     {
//         path: '/private',
//         component: () => import('../views/Support/Private.vue')
//     },
//     {
//         path: '/login',
//         name: '/login',
//         component: () => import('../views/Login.vue')
//     }
// ];
const routes = [
    {
        path: "/",
        component: () => import("../views/Index.vue"),
    },
    {
        path: "/cet4",
        component: () => import("../views/Index.vue"),
    },
    {
        path: "/user",
        component: () => import("../views/User/User.vue"),
        meta: {
            needLogin: true,
        }
    },
    {
        path: '/listen',
        component: () => import('../views/Listen/Listen.vue')
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

const { user } = useUser();
const LOGIN_PAGE_NAME = '/login';
router.beforeEach((to, from, next) => {
    const openid = user.openid;
    console.log('to', to)
    if (to.meta.needLogin && !openid) {
        next({
            name: LOGIN_PAGE_NAME // 跳转到登录页
        })
    } else if (openid && to.name === LOGIN_PAGE_NAME) {
        // 已登录且要跳转的页面是登录页 - 跳转到homeName页
        next({ name: '/'})
    } else {
        next();
    }
})

export default router;
